<template>
  <div>
    <div class="header pb-6 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-white opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex">
        <b-row>
          <b-col sm="12" md="auto" class="mt-4">
            <div
              @click="$router.back()"
              class="icon icon-shape rounded-circle shadow bg-primary text-white"
            >
              <i class="fa fa-arrow-left"></i>
            </div>
          </b-col>
          <!-- <b-col sm="12" md="auto" class="mt-2">
                        <h1 class="text-primary display-1">Participants</h1>
                    </b-col> -->
        </b-row>
      </b-container>
    </div>

    <b-container fluid class="mt--6">
      <!-- Participant Table -->
      <b-row class="mt-4">
        <b-col sm="12">
          <HackathonParticipantTable :hackathon_id="hackathon_id" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// Components
import HackathonParticipantTable from "@/views/Components/Hackathon/Participants/ParticipantTable.vue";
import { GET_HACKATHON_HACKATHON } from "@/graphql/queries";
import { Hackathon } from "@/ORM/Hackathon/Hackathon.js";

export default {
  name: "ViewHackathonParticipants",
  components: {
    HackathonParticipantTable,
  },
  apollo: {
    get_hackathon: {
      query: GET_HACKATHON_HACKATHON,
      result({ data }) {
        if (!data || !data.hackathonHackathon) {
          this.redirect();
          return;
        }

        const hackathon = new Hackathon(data.hackathonHackathon);

        if (
          !hackathon.is_creator &&
          !hackathon.is_manager &&
          !hackathon.is_mentor
        ) {
          this.redirect();
          return;
        }

        this.hackathon = hackathon;
      },
      update(data) {
        this.apollo_data.get_hackathon = data;
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name);
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          id: this.hackathon_id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {},
      hackathon_id: null,
      hackathon: null,
    };
  },
  methods: {
    // Apollo Methods
    enable_query(query) {
      if (!query.skip) {
        query.refetch();
      } else {
        query.skip = false;
        query.start();
      }
    },
    redirect() {
      this.$router.push({
        name: "HackathonView",
        params: { hackathon_id: this.hackathon_id },
      });
    },

    // ID Management
    get_hackathon_id() {
      if (this.$route.params.hackathon_id) {
        this.hackathon_id = this.$route.params.hackathon_id;
      } else {
        this.hackathon_id = null;
        this.redirect();
      }
    },
  },
  created() {
    this.get_hackathon_id();
    this.enable_query(this.$apollo.queries.get_hackathon);
  },
};
</script>

<style></style>
